import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import Landing from '../components/Landing/Landing'
import Footer from '../components/Footer'

class Home extends Component {
	render() {
		return(
			<React.Fragment>
				<Helmet
                    htmlAttributes={{
                        lang: 'en',
                    }}
                >
					<meta charSet="utf-8" />
					<title>Amol Garhwal | Home</title>
					<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;800;900&display=swap"/>
					<meta name="description" 
					content="Hi, I'm Amol. I am a MERN Stack Developer and this is my portfolio website." />
				</Helmet>
				<Header selected="home" />
				<Landing />
				<Footer />
			</React.Fragment>
		)
	}
}

export default Home