import React, { Component } from 'react'

class TxtRotate {
    constructor(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 1000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };
    tick() {
        let i = this.loopNum % this.toRotate.length;
        let fullTxt = this.toRotate[i];
  
        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
  
        this.el.innerHTML = '<span class="autoType">' + this.txt + '</span>';
  
        let that = this;
        let delta = 100 - Math.random() * 100;
  
        if (this.isDeleting) { delta /= 2;} 
        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 100;
        }
        setTimeout(function () {
            that.tick();
        }, delta)
    };
};

class Landing extends Component {
    componentDidMount() {
        const elements = document.getElementsByClassName('skillset');
        for (let i=0; i<elements.length; i++) {
            let toRotate = elements[i].getAttribute('data-rotate');
            let period = elements[i].getAttribute('data-period');
            if (toRotate) {
                new TxtRotate(elements[i], JSON.parse(toRotate), period);
            }
        };
        let css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".skillset > .autoType { border-right: 0.125rem solid #28A745 }";
        document.body.appendChild(css);
    }
    
    render() {
        return(
            <div className="container ck-container-landing">
                <h1 className="ck-heading-main">
                    Hey there, I'm Amol.
                </h1>
                <h1 className="ck-subheading-main">
                    I'm a <span className="skillset" data-period="1000" data-rotate='["MERN Stack Developer.", "Blockchain Developer.", "Tech Enthusiast."]'></span>
                </h1>
            </div>
        )
    }
}

export default Landing